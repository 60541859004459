import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoadingPage.css';

const LoadingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const userDetails = localStorage.getItem('userData');

    // Check if userDetails is null or empty
    let data = null;
    if (userDetails) {
      try {
        data = JSON.parse(userDetails);
      } catch (error) {
        console.error('Error parsing userData:', error);
        data = null;
      }
    }

    

    const redirectTo = token && data ? '/board' : '/register';

    const timer = setTimeout(() => {
      navigate(redirectTo, { state: { user: data } });
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="background">
      <div className='register'>
        <div className="header">
          <div className="coin-container" aria-label="Coins">
            <span className="coin" aria-hidden="true">💰</span>
            <span className="coin" aria-hidden="true">💰</span>
            <span className="coin" aria-hidden="true">💰</span>
            <span className="coin" aria-hidden="true">💰</span>
          </div>
          Fortune CM
        </div>
        <div className="loading-container">
          <div className="loading-spinner" role="status" aria-label="Loading Spinner"></div>
          <div className="loading-bar">
            <div className="progress"></div>
          </div>
          <p>Loading...</p>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;

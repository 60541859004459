import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './InvitePage.css'; // Ensure this CSS file exists
import referralLogo from '../components/images/referral.png';
import copyLogo from '../components/images/copy.png';


const InvitePage = () => {
  const [referralCount, setReferralCount] = useState('');
  const [copyMessage, setCopyMessage] = useState('');
  const [data, setData] = useState('');

  
  const navigate = useNavigate();
  const location = useLocation();
 // Initialize user
 const user = location.state?.user || JSON.parse(localStorage.getItem('userData'));
  // Tab 
  // Tab 
  const [activeTab, setActiveTab] = useState('home'); // Active tab state

useEffect(() => {
  const currentPath = location.pathname.split('/')[1]; // Get the path after the first '/'
  setActiveTab(currentPath || 'home'); // Default to 'home' if path is empty
}, [location]);

const handleTabClick = (tab) => {
  setActiveTab(tab);
  navigate(`/${tab}`);
};


// Fetch user data
useEffect(() => {
  const fetchData = async () => {
    try {
      const username = await user.username;
      const response = await axios.get(`https://fortunecm.icu/earncoin/api-php/getdata.php?username=${username}`);
      setData(response.data.data[0]); // Set the data state
      //console.log(data)
    } catch (error) {
      // Handle error
    }
  };

  fetchData(); // Call the fetch function
}, [user]);



  const copyLink = () => {
    navigator.clipboard.writeText('https://t.me/fortunecm_bot')
      .then(() => {
        setCopyMessage('Link copied!');
        setTimeout(() => setCopyMessage(''), 2000); // Clear message after 2 seconds
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(data?.referral_code)
      .then(() => {
        setCopyMessage('Referral code copied!');
        setTimeout(() => setCopyMessage(''), 2000); // Clear message after 2 seconds
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };



  

  return (
    <div className="invite-page">
      <header>
        <div className="header-content">
          <h1 style={{color:'white'}}>Invite Friends</h1>
          <p style={{color:'yellow', display:'flex', flexDirection:'row'}}>
            You have  {data?.referral_count} referrals!</p>
        </div>
      </header>
       
      
        <section className="invite-content">
          <img src={referralLogo} alt="Referral" className="referral-image" />
          <p style={{display:'flex', textAlign:'center', color:'blue', fontSize:16, fontWeight:'bold'}}>+2000 Point</p>
           
          <div className="referral-instructions">
            <h3>How to Refer:</h3>
            <ol>
              <li>Share your referral link with friends.</li>
              <li>Tell them to sign up using your referral code</li>
              <li>When they sign up using your code, you both earn rewards!</li>
            </ol>
          </div>
 
          
            <div style={{}}>
                <h4 style={{marginBottom:-10}}>Referral Link:</h4>
              <span id="code" onClick={copyLink}> https://t.me/fortunecm_bot  
                <img style={{width:20, marginLeft: 10}} src={copyLogo} alt="Copy Icon"  /> Copy</span>
            </div>
            
            { data?.referral_code === undefined ? (
          <div className="loading">
           
            <div className="spinner"></div>
        </div>
        ): (
            <div style={{marginTop:20}}>
                <h4 style={{color:'green', marginBottom:-10}}>Referral Code:</h4>
              <span id="code" onClick={copyToClipboard}> {data?.referral_code } 
                <img style={{width:20, marginLeft: 10}} src={copyLogo} alt="Copy Icon"  /> Copy</span>
            </div>
         
        )}
         {copyMessage && <p className="copy-message">{copyMessage}</p>}
        </section>
     
      
     
        
        <div className="tabs">
              <div
                className={`tab ${activeTab === 'board' ? 'active' : ''}`}
                onClick={() => handleTabClick('board')}
              >
                Home
              </div>
              <div
                className={`tab ${activeTab === 'task' ? 'active' : ''}`}
                onClick={() => handleTabClick('task')}
              >
                Task
              </div>

            
              <div
                className={`tab ${activeTab === 'video' ? 'active' : ''}`}
                onClick={() => handleTabClick('video')}
              >
             
                
               More
              </div>
               

              <div
                className={`tab ${activeTab === 'invite' ? 'active' : ''}`}
                onClick={() => handleTabClick('invite')}
              >
                
                Refer
              </div>
              <div
                className={`tab ${activeTab === 'wallet' ? 'active' : ''}`}
                onClick={() => handleTabClick('wallet')}
              >
                Wallet
              </div>
            </div>

     
    </div>
  );
};

export default InvitePage;

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import RegisterPage from './components/RegisterPage';
import LoginPage from './components/LoginPage';
import BoardPage from './board/BoardPage';

import InvitePage from './board/InvitePage';
import WalletPage from './board/WalletPage';
import LoadingPage from './components/LoadingPage';
import GridComponent from './board/gbutton';
import YouTubePlayer from './board/YoutubePlayer';
import VideoSelection from './board/YoutubeSelection';
import YouTubeEmbed from './board/Iframe';
import TaskPage from './board/TaskPage';
import UserStatistics from './components/UserStat';


function App() {
    const [videoId, setVideoId] = useState(null);
    
    const [watchedVideos, setWatchedVideos] = useState(() => {
        const storedVideos = localStorage.getItem('watchedVideos');
        return storedVideos ? JSON.parse(storedVideos) : [];
    });

    return (
        <Router>
            <Routes>
                <Route path="/" element={<LoadingPage />} />
                <Route path="/grid" element={<GridComponent />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/board" element={<BoardPage />} />
                <Route path="/task" element={<TaskPage />} />
                <Route path="/invite" element={<InvitePage />} />
                <Route path="/wallet" element={<WalletPage />} />
                <Route path="/userstat" element={<UserStatistics />} />
               
                <Route path="/iframe" element={<YouTubeEmbed videoId="Ou--k7i3dUM" />} />
                
                <Route 
                    path="/youtube" 
                    element={<YouTubePlayer videoId={videoId} watchedVideos={watchedVideos} setWatchedVideos={setWatchedVideos} />} 
                />
                <Route 
                    path="/video" 
                    element={<VideoSelection setVideoId={setVideoId} watchedVideos={watchedVideos} />} 
                />
            </Routes>
        </Router>
    );
}

export default App;


import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './YoutubeSelection.css';
import youtubeLogo from '../components/images/youtube2.png';

const VideoSelection = ({ setVideoId }) => {
   

    const [activeTab, setActiveTab] = useState('video');
    const navigate = useNavigate(); 
  const location = useLocation();
  const user = location.state?.user || JSON.parse(localStorage.getItem('userData'));

     // Tab handling
  useEffect(() => {
    const currentPath = location.pathname.split('/')[1];
    setActiveTab(currentPath || 'video');
  }, [location]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    navigate(`/${tab}`);
  };

  // Fetch user data
  const [data, setData] = useState('');
useEffect(() => {
    const fetchData = async () => {
      try {
        const username = await user.username;
        const response = await axios.get(`https://fortunecm.icu/earncoin/api-php/getdata.php?username=${username}`);
        setData(response.data.data[0]); // Set the data state
        //console.log(data)
      } catch (error) {
        // Handle error
      }
    };
  
    fetchData(); // Call the fetch function
  }, [user]);
 
    const allVideos = [ 
      { id: '9AP2Sg8Uo-E', title: 'Watch' },
      { id: 'ezzv8HERYMU', title: 'Watch' },
      { id: 'EVu2NHnFIFc', title: 'Watch' },
      { id: 'Fy1GVweJHVA', title: 'Watch' },
      { id: 'Ou--k7i3dUM', title: 'Watch' },
       { id: 'OoZybQmHHGA', title: 'Watch' },
        { id: 'PicmvEHz2W0', title: 'Watch' },
        { id: 'xJW5OX9ZEU4', title: 'Watch' },
        { id: 'RF8GKH-ixiQ', title: 'Watch' },
        { id: 'tfmcPAHLhpg', title: 'Watch' },
        { id: 'yos2fQ38kkg', title: 'Watch' },

        { id: 'qr24nQiaCV8', title: 'Watch' },
      { id: 'QKgpsYov9K0', title: 'Watch' },

      { id: 'gquG7nyzdek', title: 'Watch' },
      { id: 'KKrySWn6sKQ', title: 'Watch' },

      { id: '-qW93G1Qwhg ', title: 'Watch' },
      { id: 'rZTKB9NnyuY', title: 'Watch' },
 

      { id: 'gquG7nyzdek', title: 'Watch' },
      { id: '-qW93G1Qwhg', title: 'Watch' },
     
      { id: 'qtyem5-2FO4', title: 'Watch' },
      { id: 'DAra5_qvKhc', title: 'Watch' },

    ]; 

    const [currentVideo, setCurrentVideo] = useState(null);
    const [remainingTime, setRemainingTime] = useState(0);
    const [nextVideo, setNextVideo] = useState(null);
    const [watchedVideos, setWatchedVideos] = useState(() => {
        const storedVideos = localStorage.getItem('watchedVideos');
        return storedVideos ? JSON.parse(storedVideos) : [];
    });

  

    useEffect(() => {
        const nextVideo = allVideos.find(video => !isVideoWatched(video.id));
        setCurrentVideo(nextVideo || null);
    }, [watchedVideos]);

    const isVideoWatched = (videoId) => {
        const watchedInfo = watchedVideos.find(video => video.videoId === videoId);
        if (!watchedInfo) return false;
        const daysWatched = (Date.now() - watchedInfo.timestamp) / (1000 * 60 * 60 * 24);
        return daysWatched < 30;
    };

    const handleVideoSelect = (id) => {
        // To remove a specific item
       //localStorage.removeItem('watchedVideos'); // Replace 'watchedVideos' with the key you want to remove
        //localStorage.clear();
        setVideoId(id);
        //setRemainingTime(1)

         
        navigate('/youtube');
       
    };

    useEffect(() => {
        const endTime = localStorage.getItem('nextVideoEndTime');
        if (endTime) {
            const timeLeft = Math.max(0, parseInt(endTime) - Date.now());
            setRemainingTime(timeLeft);

            const timerId = setInterval(() => {
                setRemainingTime(prev => {
                    if (prev <= 1000) {
                        clearInterval(timerId);
                        const availableNextVideo = allVideos.find(video => !isVideoWatched(video.id));
                        setNextVideo(availableNextVideo);
                        localStorage.removeItem('nextVideoEndTime');
                        return 0;
                    }
                    return prev - 1000;
                });
            }, 1000);

            return () => clearInterval(timerId);
        } else {
            setNextVideo(allVideos.find(video => !isVideoWatched(video.id)));
        }
    }, [watchedVideos]);

    return (

        <div className='task-container'>

            <header className="header">
                <div className="user-stats">
                <span className="title-point">Points:{ data?.points}</span>
                <span className="title-task">Tasks Completed: {data?.totaltask}</span>
                </div>
                <div className="task-title" >
                <span className="balance-label">Daily Task</span>
                </div>
               
            </header>


        <div className='grid'>
            <div style={{display:'flex-block', justifyContent:'center', justifyItems:'center', alignItems:'center'}}>
                    {remainingTime > 0 ? (
                        <>
                            {currentVideo && (
                                <div style={{display:'flex-block', justifyContent:'center', justifyItems:'center', alignItems:'center'}}>
                                    
                                    <button 
                                        onClick={() => handleVideoSelect(currentVideo.id)}
                                        style={{ margin: '5px', display:'none' }}
                                        disabled={remainingTime > 0}
                                    >
                                        {currentVideo.title}
                                    </button>
                                </div>
                            )}
                           
                           <h3 className="countdown-container">
  <span className="countdown-title">
    Next video available in:
  </span>
  <div className="countdown-timer">
    <div className="time-block">
      <span className="time-value">{Math.floor(remainingTime / 3600000)}</span>
      <span className="time-label">hrs</span>
    </div>
    <div className="time-block">
      <span className="time-value">{Math.floor((remainingTime % 3600000) / 60000)}</span>
      <span className="time-label">min</span>
    </div>
    <div className="time-block">
      <span className="time-value">{Math.floor((remainingTime % 60000) / 1000)}</span>
      <span className="time-label">sec</span>
    </div>
  </div>
</h3>


                        </>
                    ) : (
                        nextVideo && (
                         <div style={{display:'inline-grid', justifyContent:'center', 
                            alignItems:'center', justifyItems:'center'}}>
                              <h2 style={{color: 'yellow'}}>Next Video Available</h2>
                              <p style={{color:'white', fontSize:12, textAlign:'center'}}>
                              Watch the video until the end. Your points will be 
                              automatically redeemed after the video finishes.
                                </p>
                            <div className='grid-item' onClick={() => handleVideoSelect(nextVideo.id)} >
                                
                                <img className='grid-image-youtube' src={youtubeLogo}  alt='' />
                               
                                    {nextVideo.title}
                               
                            </div>
                            </div>
                        )
                    )}
                    {!currentVideo && !remainingTime && <h2>No more videos to watch!</h2>}
                </div>

            </div>

         
            <div className="tabs">
              <div
                className={`tab ${activeTab === 'board' ? 'active' : ''}`}
                onClick={() => handleTabClick('board')}
              >
                Home
              </div>
              <div
                className={`tab ${activeTab === 'task' ? 'active' : ''}`}
                onClick={() => handleTabClick('task')}
              >
                Task
              </div>

             
              <div
                className={`tab ${activeTab === 'video' ? 'active' : ''}`}
                onClick={() => handleTabClick('video')}
              >
             
                
               More
              </div>
                

              <div
                className={`tab ${activeTab === 'invite' ? 'active' : ''}`}
                onClick={() => handleTabClick('invite')}
              >
                
                Refer
              </div>
              <div
                className={`tab ${activeTab === 'wallet' ? 'active' : ''}`}
                onClick={() => handleTabClick('wallet')}
              >
                Wallet
              </div>
            </div>

         
            
        </div>
    );
};

export default VideoSelection;

import React, { useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './TaskPage.css';
import youtubeLogo from '../components/images/youtube2.png';
import telegramLogo from '../components/images/telegram.png';
import tiktokLogo from '../components/images/tiktok.png';

import claimLogo from '../components/images/claimtasktel.png';


const TaskPage = () => {

  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpenAnswer, setModalOpenAnswer] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTittle, setModalTitle] = useState('');
  const [modalTag, setModalTag] = useState('');
  const [modalUrl, setModalUrl] = useState('');
  const [linkCode, setLinkCode] = useState('');
  const [redeemCode, setRedeemCode] = useState('');
  const [disabledItems, setDisabledItems] = useState(Array(12).fill(false));
  const [currentItemIndex, setCurrentItemIndex] = useState(null);
  const [message, setMessage] = useState('');
  const [data, setData] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  
 // Initialize user
 const user = location.state?.user || JSON.parse(localStorage.getItem('userData'));
  // Tab 
  const [activeTab, setActiveTab] = useState('task'); // Active tab state

useEffect(() => {
  const currentPath = location.pathname.split('/')[1]; // Get the path after the first '/'
  setActiveTab(currentPath || 'task'); // Default to 'home' if path is empty
}, [location]);

const handleTabClick = (tab) => {
  setActiveTab(tab);
  navigate(`/${tab}`);
};

// Fetch user data
useEffect(() => {
  const fetchData = async () => {
    try {
      const username = await user.username;
      const response = await axios.get(`https://fortunecm.icu/earncoin/api-php/getdata.php?username=${username}`);
      setData(response.data.data[0]); // Set the data state
      //console.log(data)
    } catch (error) {
      // Handle error
    }
  };

  fetchData(); // Call the fetch function
}, [user]);



  useEffect(() => {
    // Check localStorage for saved expiration time
    const savedTime = localStorage.getItem('expirationTime');
    if (savedTime) {
      const timeLeft = parseInt(savedTime, 10) - Date.now();
      if (timeLeft > 0) {
        setDisabledItems(prev => {
          const newDisabledItems = [...prev];
          const index = parseInt(localStorage.getItem('disabledIndex'), 10);
          newDisabledItems[index] = true;
          return newDisabledItems;
        });

        // Start countdown
        const countdown = setInterval(() => {
          const updatedTimeLeft = timeLeft - 1000;
          if (updatedTimeLeft <= 0) {
            clearInterval(countdown);
            const updatedDisabledItems = [...disabledItems];
            const index = parseInt(localStorage.getItem('disabledIndex'), 10);
            updatedDisabledItems[index] = false;
            setDisabledItems(updatedDisabledItems);
            localStorage.removeItem('expirationTime');
            localStorage.removeItem('disabledIndex');
          }
        }, 1000);
        return () => clearInterval(countdown);
      } else {
        localStorage.removeItem('expirationTime');
        localStorage.removeItem('disabledIndex');
      }
    }
  }, []);

  //Reload Page
const handleReload = () => {
  window.location.reload();
};

  const handleOpenModal = (title, item, tag, url, codelink, index) => {
    setModalTitle(title)
    setModalContent(item);
    setModalTag(tag)
    setModalUrl(url);
    setLinkCode(codelink);
    setModalOpen(true);
    setRedeemCode('');
    setCurrentItemIndex(index);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalContent('');
    setModalTag('');
    setModalUrl('');
    setLinkCode('');
    setModalTitle('');
    setRedeemCode('');
    setCurrentItemIndex(null);
    handleReload();
  };


  const handleOpenModalAswer = () => {
   
    setModalOpenAnswer(true);
    setRedeemCode('');
    
  };

  // Answer
  const handleCloseModalAnswer = () => {
    setModalOpenAnswer(false);
    setRedeemCode('');
     handleReload();
  };

  const openLink = async () => {
  

    const username = await user.username;
    
    const userLink = {
      username,
      linkCode
      
    };

    console.log(linkCode)
   
    try {
      const response = await fetch('https://fortunecm.icu/earncoin/api-php/link.php', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userLink)
      });
      const result = await response.json();

      if (result.status === 'success') {
        console.log('success');
      }
    } catch (error) {
      console.log('An error occurred:', error);
    
    }
  };

  // Check Claim code
  const [claimedCodes, setClaimedCodes] = useState(() => {
    const storedCodes = localStorage.getItem('claimedCodes');
    return storedCodes ? new Set(JSON.parse(storedCodes)) : new Set();
});




const handleClaimReward = async (e) => {
  e.preventDefault();

  if (claimedCodes.has(redeemCode)) {
    setMessage('Code already used'); // Set the message
    setTimeout(() => {
      setMessage(''); // Clear the message after 3 seconds
    }, 3000);
    return; // Exit if the code already exists
  }

 
  const username = await user.username;
  const newPoints = 500; // Example point value

  const userData = {
    username,
    redeemCode,
    modalUrl,
    newPoints
  };

  try {
    const response = await fetch('https://fortunecm.icu/earncoin/api-php/redeem.php', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userData)
    });
    const result = await response.json();

    if (result.status === 'success') {
      claimedCodes.add(redeemCode); // Add the redeemed code
      setClaimedCodes(new Set(claimedCodes)); // Update the state

      // Update localStorage
      localStorage.setItem('claimedCodes', JSON.stringify(Array.from(claimedCodes)));

      if (currentItemIndex !== null) {
        const expirationTime = Date.now() + 259200000000000; // 30 days in milliseconds
        localStorage.setItem(`disabledItem_${currentItemIndex}`, expirationTime);
        
        // Disable the item visually
        const newDisabledItems = [...disabledItems];
        newDisabledItems[currentItemIndex] = true;
        setDisabledItems(newDisabledItems);

        setMessage('Reward Claimed');
        setTimeout(() => {
          setMessage('');
        }, 3000);
      }

      handleCloseModal();
    } else {
      setMessage('Invalid Redeem Code');
      setTimeout(() => {
        setMessage('');
      }, 3000);
    }

  } catch (error) {
    console.log('An error occurred:', error);
  }
};

// Effect to check local storage for disabled items on component mount
useEffect(() => {
  const checkDisabledItems = () => {
    const updatedDisabledItems = [...disabledItems];
    for (let i = 0; i < updatedDisabledItems.length; i++) {
      const expirationTime = localStorage.getItem(`disabledItem_${i}`);
      if (expirationTime && Date.now() < parseInt(expirationTime, 10)) {
        updatedDisabledItems[i] = true; // Set as disabled
      } else {
        localStorage.removeItem(`disabledItem_${i}`); // Clean up expired items
      }
    }
    setDisabledItems(updatedDisabledItems);
  };

  checkDisabledItems();
}, []);


const normalizedItems = disabledItems.map(item => Number(item)); // Normalize to numbers

const allDisabled = [0, 1, 2].filter(item => normalizedItems.includes(item));
//console.log('All Disabled:', allDisabled);


const removeT = ()=>{
  localStorage.removeItem(`'claimedCodes`);
  const expirationTime = Date.now() + 2000; // 30 days in milliseconds
        localStorage.setItem(`disabledItem_${2}`, expirationTime);
        
}


const handleClaimRewardAnswer = async (e) => {
  e.preventDefault();

  if (claimedCodes.has(redeemCode)) {
    setMessage('Code already used'); // Set the message
    setTimeout(() => {
      setMessage(''); // Clear the message after 3 seconds
    }, 3000);
    return; // Exit if the code already exists
  }
 
  const username = await user.username;
  const newPoints = 800; // Example point value

  const userData = {
    username,
    redeemCode,
    newPoints
  };

  try {
    const response = await fetch('https://fortunecm.icu/earncoin/api-php/redeem-answer.php', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userData)
    });
    const result = await response.json();
      console.log(redeemCode)
    if (result.status === 'success') {
      claimedCodes.add(redeemCode); // Add the redeemed code
      setClaimedCodes(new Set(claimedCodes)); // Update the state

      // Update localStorage
      localStorage.setItem('claimedCodes', JSON.stringify(Array.from(claimedCodes)));

        setMessage('Reward Claimed');
        setTimeout(() => {
          setMessage('');
        }, 5000);
      
      handleCloseModalAnswer();
    } else {
      setMessage('Wrong Answer');
      setTimeout(() => {
        setMessage('');
      }, 3000);
    }

  } catch (error) {
    console.log('An error occurred:', error);
  }
};



  return (
    <div className="task-container">
      <header className="header">
     
                 <div className="user-stats">
                  <span className="title-point">Points: {data?.points}</span>
                  <span className="title-task">Tasks Completed: {data?.totaltask}</span>
                </div>
           <div className="task-title" >
            <span className="balance-label" >Daily Task</span>
          </div>
          <span style={{color:'green'}}>{message}</span>
      </header>

     
     
      <div style={{marginTop:100}}></div>
        
   
       
        
      <div className="gridTask">
        <div className={`grid-item-task ${disabledItems[0] ? 'disabled' : ''}` }
         onClick={() => handleOpenModal('YouTube', 
          'Subscribe to YouTube Channel.', 'Click more to View the code in the About section',
           'https://www.youtube.com/channel/UCteCvHE8wVXehlIqB05uwzA?sub_confirmation=1',  19035224, 0)} 
        disabled={disabledItems[0]}
       
      >
          <img src={youtubeLogo} alt="Item 1" className="grid-image" />
          <p style={{marginBottom:-15}}>Youtube Channel</p>
          <h5 style={{color:'blue'}}>Subscribe + 500</h5>
         
        </div>


        <div className={`grid-item-task ${disabledItems[1] ? 'disabled' : ''}`}
        onClick={() => handleOpenModal('Telegram', 'Join Telegram Channel', 'View the code in the telegram channel post',
          'https://t.me/fortuneclick', 847368,  1)} 
        disabled={disabledItems[1]}
        >
          <img src={telegramLogo} alt="Item 2" className="grid-image" />
          <p style={{marginBottom:-15}}>Telegram Community</p>
          <h5 style={{color:'blue'}}> Join + 500</h5>
         
        </div>

        


        <div className={`grid-item-task ${disabledItems[2] ? 'disabled' : ''}`}
        
         onClick={() => handleOpenModal('Tiktok', 'Follow us on tiktok', 'View the code in tiktok Bio',
            'https://www.tiktok.com/@fortuneclick1280?is_from_webapp=1&sender_device=pc', 7236263, 2)} 
         disabled={disabledItems[2]}
        >
          <img src={tiktokLogo} alt="Item 3" className="grid-image" />
          <p style={{marginBottom:-15}}>Tiktok Community</p>
          <h5 style={{color:'blue'}}>Follow + 500</h5>
         
        </div>


        <div className={`grid-item-task ${disabledItems[4] ? 'disabled' : ''}`}
        onClick={() => handleOpenModal('Telegram', 'Join Telegram Channel', 'View the code in the telegram channel post',
          'https://t.me/fortunecmchat', 56372810,  4)} 
        disabled={disabledItems[4]}
        >
          <img src={telegramLogo} alt="Item 4" className="grid-image" />
          <p style={{marginBottom:-15}}>Fortune Update Chat</p>
          <h5 style={{color:'blue'}}> Join + 500</h5>
         
        </div>


        

        
         
         <div onClick={handleOpenModalAswer} style={{display:'flex', color:'white', justifyContent:'center', 
          alignItems:'center', justifyItems:'center', marginTop:40}}>
             <img src={claimLogo} className='blink' style={{width:150, height:100}}/>
             
         </div>
     
        
       
      
       
      </div>
   
         
  {/* Conditional rendering of "More Task" button */}
  {allDisabled.length  && (
  <button className="more-task-button" onClick={() => navigate('/video')}>
    More Task
  </button>
)}

        


        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal">
              <h2>{modalTittle}</h2>
              <p style={{fontSize:12}}>{modalContent}</p>
              <p className='mo-content'> {`Click the open link button and ${modalContent}. 
                ${modalTag}, Use the code to redeem your reward.`}
              </p>

              <button className="modal-button" onClick={() => { 
                window.open(modalUrl, '_blank');
                //handleCloseModal();
                openLink()
              }}>
                Open Link
              </button>
              <span style={{color:'blue'}}>The Code only works when you open the link and {modalContent} </span>
               <span style={{color:'red'}}>{message}</span>
              <form onSubmit={handleClaimReward}>
                <input
                  type="text"
                  placeholder="Enter redeem code"
                  value={redeemCode}
                  onChange={(e) => setRedeemCode(e.target.value)}
                  className="redeem-input"
                  required
                />
                <button type="submit" className="modal-button claim-button">Claim Reward</button>
              </form>

              
              <button className="modal-button-close" onClick={handleCloseModal}>
                Close
              </button>
            </div>
          </div>
        )}


{isModalOpenAnswer && (
          <div className="modal-overlay">
            <div className="modal">
              <h2>Claim Watched Reward</h2>
              <p style={{fontSize:12}}>Check out our daily video posts on Telegram. Watch each
                 video to find the question and its answer, so pay close attention! 
                 Please enter the correct answer below, and remember to check the 
                 Telegram channel for the question. </p>
              <p className='mo-content' style={{color: 'blue'}}> If your answer is a letter, it must be in capital letters. </p>
              <span style={{color:'red'}}>{message}</span>
              <form onSubmit={handleClaimRewardAnswer}>
                <input
                  type="text"
                  placeholder="Answer"
                  value={redeemCode}
                  onChange={(e) => setRedeemCode(e.target.value)}
                  className="redeem-input"
                  required
                />
                <button type="submit" className="modal-button claim-button">Claim Reward</button>
              </form>

              
              <button className="modal-button-close" onClick={handleCloseModalAnswer}>
                Close
              </button>
            </div>
          </div>
        )}

         
     { data?.username === 'ayalsuccess4' ?
      <button style={{marginTop:20, backgroundColor:'yellow', alignItems:'center', alignSelf:'center',
        justifyContent:'center'}} onClick={()=>navigate('/userstat')}>Statistics</button>
      : null }  
    
        <div style={{marginTop:100}}></div>
 
      
       <div className='tabs'>
              <div
                className={`tab ${activeTab === 'board' ? 'active' : ''}`}
                onClick={() => handleTabClick('board')}
              >
                Home
              </div>
              <div
                className={`tab ${activeTab === 'task' ? 'active' : ''}`}
                onClick={() => handleTabClick('task')}
              >
                Task
              </div>
              <div
                className={`tab ${activeTab === 'more' ? 'active' : ''}`}
                onClick={() => handleTabClick('video')}
              >
                More
              </div>
              <div
                className={`tab ${activeTab === 'invite' ? 'active' : ''}`}
                onClick={() => handleTabClick('invite')}
              >
                Refer
              </div>
              <div
                className={`tab ${activeTab === 'wallet' ? 'active' : ''}`}
                onClick={() => handleTabClick('wallet')}
              >
                Wallet
              </div>
            </div>
     
      
      
       
    
     
    </div>
    
  );
};

export default TaskPage;
